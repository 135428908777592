import React from "react";
import Img from "../assets/img/1.svg";
import FeatherIcon from "feather-icons-react";
const Sidebar = () => {
  return (
    <div className="app-sidebar-container">
      <div className="app-sidebar-container-wraper">
        <div className="app-sidebar-container-header">
          <img src={Img} alt="msmar" width={100} />
          {/* <h1 className="h2 mx-3">Msmar</h1> */}
        </div>
        <div className="app-sidebar-container-body">
          <section>
            <h1 className="h4">Get In Touch</h1>
            <p style={{ fontSize: "12px" }}>
              In Msmar company We are leveraging modern approaches to develop
              software solutions for agents that make their customer life
              easier.
            </p>
          </section>

          <section>
            <div>
              <div className="d-flex flex-row align-center ">
                <FeatherIcon icon="mail" size={23} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Chat to us
                </span>
              </div>
              <span
                style={{
                  marginTop: "25px",
                  marginLeft: "35px",
                  fontSize: "12px",
                }}
              >
                info@msmar.tech
              </span>
            </div>
          </section>

          <section>
            <div>
              <div className="d-flex flex-row align-center ">
                <FeatherIcon icon="phone-call" size={23} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Phone
                </span>
              </div>
              <span
                style={{
                  marginTop: "25px",
                  marginLeft: "35px",
                  fontSize: "12px",
                }}
              >
                +964 775 250 5097
              </span>
            </div>
          </section>
        </div>
      </div>
      <div className="app-sidebar-container-footer">
        <section>
          <div>
            <a
              href="https://web.facebook.com/msmargroup"
              target="_blank"
              rel="noreferrer"
            >
              <FeatherIcon icon="facebook" color="white" alt="Facebook" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/msmargroup/"
              target="_blank"
              rel="noreferrer"
            >
              <FeatherIcon icon="instagram" color="white" alt="Instagram" />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/in/msmargroup/"
              target="_blank"
              rel="noreferrer"
            >
              <FeatherIcon icon="linkedin" color="white" alt="LinkedIn" />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sidebar;
