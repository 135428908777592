import React from "react";

import Form from "react-bootstrap/Form";

const ContactUs = ({ mobileMode }) => {
  return (
    <div className="app-body-contact">
      <div className="app-body-contact-header">
        <h1>Level Up Your Brand</h1>
        <p>
          You can reach us via{" "}
          <a href="mailto:info@msmar.tech">info@msmar.tech</a>
        </p>
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type="number" placeholder="Enter Phone Number" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>How can we help? </Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>

        <Form.Label>Services </Form.Label>
        <div className="services">
          <Form.Group className="d-flex flex-column align-items-start">
            <Form.Group controlId="serverHosting">
              <Form.Check
                id="serverHosting"
                className="fs-5 text-nowrap checkbox-label"
                type="checkbox"
                label="Server hosting"
              />
            </Form.Group>

            <Form.Group controlId="domainProvider">
              <Form.Check
                id="domainProvider"
                className=" fs-5 text-nowrap"
                type="checkbox"
                label="Domain provider"
              />
            </Form.Group>

            <Form.Group controlId="bEmailProvider">
              <Form.Check
                id="bEmailProvider"
                className=" fs-5 text-nowrap"
                type="checkbox"
                label="Business email provider"
              />
            </Form.Group>
          </Form.Group>

          <Form.Group className="d-flex flex-column align-items-start">
            <Form.Group controlId="webDev">
              <Form.Check
                id="webDev"
                className=" fs-5 text-nowrap"
                type="checkbox"
                label="Web development"
              />
            </Form.Group>

            <Form.Group controlId="mobileDev">
              <Form.Check
                id="mobileDev"
                className="fs-5 text-nowrap"
                type="checkbox"
                label="Mobile development"
              />
            </Form.Group>

            <Form.Group controlId="backDev">
              <Form.Check
                id="backDev"
                className="fs-5 text-nowrap"
                type="checkbox"
                label="Backend development"
              />
            </Form.Group>
          </Form.Group>
        </div>

        <button className="sub-btn mt-4">Submit</button>
      </Form>
    </div>
  );
};

export default ContactUs;
