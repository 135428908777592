import React from "react";
import styles from "../assets/css/footer.module.css";
import MsmarIcon from "../assets/img/1.svg";
import FeatherIcon from "feather-icons-react";

const Footer = () => {
  return (
    <footer className={styles.footer_container}>
      <div className={styles.section_one}>
        <div>
          <img src={MsmarIcon} width={150} alt="msmar" />
        </div>
        {/* <div className={styles.section_one_links}>
          <a href="/#about"> About Us</a>
          <a href="/#projects">
            {lang.lang === "EN" ? "Our Projects" : "مشاريعنا"}
          </a>
          <a href="/#contact">
            {lang.lang === "EN" ? "Contact Us" : "تواصل معنا"}
          </a>
          <Link href="/services">
            {lang.lang === "EN" ? "Services" : "ألخدمات"}
          </Link>
        </div> */}

        <div className="app-sidebar-container-body">
          <section className="d-flex flex-column align-center ">
            <h1 className="h4" style={{ textAlign: "center" }}>
              Get In Touch
            </h1>
            <p style={{ fontSize: "12px", textAlign: "center" }}>
              In Msmar company We are leveraging modern approaches to develop
              software solutions for agents that make their customer life
              easier.
            </p>
          </section>

          <section>
            <div>
              <div className="d-flex flex-row align-center ">
                <FeatherIcon icon="mail" size={23} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Chat to us
                </span>
              </div>
              <span
                style={{
                  marginTop: "25px",
                  marginLeft: "35px",
                  fontSize: "12px",
                }}
              >
                info@msmar.tech
              </span>
            </div>
          </section>

          <section>
            <div>
              <div className="d-flex flex-row align-center ">
                <FeatherIcon icon="phone-call" size={23} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Phone
                </span>
              </div>
              <span
                style={{
                  marginTop: "25px",
                  marginLeft: "35px",
                  fontSize: "12px",
                }}
              >
                +964 775 250 5097
              </span>
            </div>
          </section>
        </div>
      </div>
      <div className={styles.section_two}>
        <div>
          <a
            href="https://web.facebook.com/msmargroup"
            target="_blank"
            rel="noreferrer"
          >
            <FeatherIcon icon="facebook" color="white" alt="Facebook" />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/msmargroup/"
            target="_blank"
            rel="noreferrer"
          >
            <FeatherIcon icon="instagram" color="white" alt="Instagram" />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/msmargroup/"
            target="_blank"
            rel="noreferrer"
          >
            <FeatherIcon icon="linkedin" color="white" alt="LinkedIn" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
