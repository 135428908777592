import React, { useState, useEffect } from "react";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

function App() {
  const [mobileMode, setMobileMode] = useState({
    mode: false,
    sidebar: false,
  });
  const handleResize = () => {
    if (window.innerWidth <= "750") {
      setMobileMode({ ...mobileMode, mode: true });
    } else {
      setMobileMode({ ...mobileMode, mode: false });
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, false);
  }, []);
  return (
    <div className="app">
      <div className="app-wrapper">
        {mobileMode.mode ? (
          <div className=""></div>
        ) : (
          <div className="app-sidebar">
            <Sidebar />
          </div>
        )}

        <div
          className="app-body"
          style={
            mobileMode.mode
              ? { marginLeft: "0 !important" }
              : { marginLeft: "300px" }
          }
        >
          {/* <AboutUs /> */}
          <ContactUs mobileMode={mobileMode} />
        </div>
        {mobileMode.mode ? <Footer /> : ""}
      </div>
    </div>
  );
}

export default App;
